import { useQuery } from 'react-query';
import { apiV1 } from '../utils';
import ReactQueryKeys from '../keys';

import type { GetAllReturn } from '../../types/get-all-return.type';
import type { Testimonial } from '../../types/testimonial.type';

const getAllTestimonials = async (): Promise<GetAllReturn<Testimonial>> => {
  const response = await apiV1.get('/testimonials/live');

  return response.data.data;
};

const useGetAllTestimonials = () => {
  return useQuery([ReactQueryKeys.GET_TESTIMONIALS], getAllTestimonials, {
    keepPreviousData: true,
  });
};

export default useGetAllTestimonials;
