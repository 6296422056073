export const cards = [
  {
    src: '/stephanie.svg',
    name: 'Stephanie Patrick Obot',
    comment:
      'I can’t believe I won. This challenge was nothing near easy, it meant that i had to study hard, i had to think through the questions, it was a good motivation.Thank you GATE Academy.',
    category: 'Primary Category',
  },
  {
    src: '/ore.svg',
    name: 'Oreofeoluwa Olakanmi',
    comment:
      'At first i wasn’t sure I could do it. The test was hard, the timer went by quickly but it wasn’t as bad as I expected and I ended up answering more questions than I thought I could.',
    category: 'Primary Category',
  },
  {
    src: '/ibukun.svg',
    name: 'Ibukunoluwa Olakanmi',
    comment:
      'The challenge was quite difficult most especially the time allotted to it but it was the kind of stimulation I needed to get me ready for the new term. Thank you GATE Academy.',
    category: 'Secondary Category',
  },
  {
    src: '/arinze.svg',
    name: 'Arinzechukwu Olisagu',
    id: 4,
    comment:
      'I am very happy to be one of the winners of this challenge I found the challenge to be interesting Fbut a bit difficult. The time went by quickly but I was able to solve the problem.',
    category: 'Primary Category',
  },
];
