import styled from 'styled-components';
import { motion } from 'framer-motion';
import Image from 'next/image';

export const HeroSection = styled.section`
  width: 100%;
  padding: 6rem 3rem 8rem;
  max-width: 140rem;
  margin: 0 auto;
  box-sizing: border-box;

  & > div {
    position: relative;
  }

  @media screen and (min-width: 56.25em) {
    padding: 5rem 6rem 8rem;
    /* height: 55rem; */
  }

  @media screen and (min-width: 56.25em) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    & > div {
      flex-basis: 48%;
      max-width: 65rem;
      position: relative;
    }
  }
`;

export const HeroHeadingContainer = styled.div`
  @media screen and (max-width: 56.25rem) {
    margin-bottom: 5rem;
  }
`;

export const HeroHeading = styled.h1`
  margin-bottom: 1.5rem;
  font-size: 3rem;

  @media screen and (min-width: 43.75em) {
    font-size: 4rem;
  }

  @media screen and (min-width: 62.5em) {
    font-size: 5rem;
  }

  @media screen and (min-width: 75em) {
    font-size: 6.5rem;
  }
`;

export const HeroSubHeading = styled.p`
  margin-bottom: 1.5rem;
  font-weight: 400;
`;

export const HeroImagesContainer = styled.div`
  position: relative;
  width: 24.5rem;
  height: 21.4375rem;
  margin: auto;

  @media screen and (min-width: 26.25em) {
    width: 35.8rem;
    height: 34.3rem;
  }

  @media screen and (min-width: 50em) {
    width: 52rem;
    height: 41rem;
  }
`;

export const HeroImageContainer = styled(motion.div)`
  position: absolute;
  width: 15.625rem;
  height: 11.5425rem;
  background-color: rgb(242, 242, 242);
  border-radius: 3rem;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
  }

  @media screen and (min-width: 26.25em) {
    width: 21.5rem;
    height: 18.4rem;
    border-radius: 4rem;
  }

  @media screen and (min-width: 48.4375em) and (max-width: 74.9375em) {
    width: 24rem;
    height: 20rem;

    &[data-hero1] {
      left: 0;
      top: 25%;
      transform: translateY(-50%);
      z-index: 3;
      top: 0%;
    }

    &[data-hero2] {
      right: 0;
      top: 0;
      z-index: 2;
      left: 40%;
    }

    &[data-hero3] {
      right: 0;
      top: 65%;
      z-index: 2;
      left: 40%;
    }
  }

  @media screen and (min-width: 75em) {
    width: 30.75rem;
    height: 24.7rem;
    border-radius: 5.6rem;
  }

  &[data-hero1] {
    left: 0;
    top: 45%;
    transform: translateY(-50%);
    z-index: 3;

    @media screen and (min-width: 50em) {
      top: 50%;
    }
  }

  &[data-hero2] {
    right: 0;
    top: 0;
    z-index: 2;

    @media screen and (min-width: 50em) {
      left: 40%;
    }
  }

  &[data-hero3] {
    bottom: -26%;
    left: 25%;
    transform: translateY(-50%);
    z-index: 1;

    @media screen and (min-width: 26.25em) {
      left: 30%;
    }

    @media screen and (min-width: 50em) {
      bottom: -17rem;
      left: 28%;
    }
  }
`;

export const SectionHeading = styled.h2`
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (min-width: 62.5em) {
    font-size: 4.8rem;
    line-height: 5.2rem;
  }
`;

export const OurValues = styled.section`
  background-color: rgba(var(--color-primary), 0.1);
  padding: 4rem 0 0.8rem 0;

  & > h2 {
    font-size: 1.6rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin: 0 4.8rem;
  }

  @media screen and (min-width: 48em) {
    padding: 5.6rem 0;
  }

  @media screen and (min-width: 90em) {
    font-size: 2.4rem;
  }
`;

export const OurValuesBox = styled.div`
  width: max-content;
  padding-top: 3.7rem;
  margin: 0 auto;

  @media screen and (min-width: 48em) {
    display: grid;
    grid-template-columns: repeat(5, minmax(8.5rem, 1fr));
    justify-content: center;
  }

  @media screen and (min-width: 56.25em) {
    gap: 3.2rem;
  }

  @media screen and (min-width: 68.75em) {
    gap: 5.6rem;
  }

  @media screen and (min-width: 81.25em) {
    gap: 10.4rem;
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  gap: 1.44rem;

  & > p {
    text-align: left;
    font-size: 1.8rem;
    font-weight: 700;
  }

  @media screen and (min-width: 48em) {
    display: block;
    text-align: center;
    margin-bottom: 0;

    & > p {
      text-align: center;
    }
  }
`;

export const ServicesSection = styled.section`
  text-align: center;
  padding-block: 5.7rem;

  h2 {
    font-size: 3.8rem;
    margin: 0 2.7rem 6.7rem;
  }

  button {
    margin: 0 auto;
  }

  @media screen and (min-width: 23.75em) {
    button {
      padding: 1.4rem 4.8rem;
      font-size: 1.8rem;
    }
  }
  @media screen and (min-width: 45.625em) {
    button {
      margin: 1.6rem auto 0;
    }
  }

  @media screen and (min-width: 100em) {
    max-width: 160rem;
    margin: 0 auto;
  }
`;

export const Services = styled.div`
  padding: 0 4.5rem;

  @media screen and (min-width: 45.625em) {
    display: grid;
    gap: 3.2rem;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }

  @media screen and (min-width: 56.25em) {
    gap: 6.4rem;
  }

  @media screen and (min-width: 60.4375em) {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }

  @media screen and (min-width: 81.25em) {
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  }
`;
export const Service = styled.div`
  box-shadow: 0.5rem 0.5rem 0.8rem rgba(233, 233, 233, 0.25);
  padding: 4.6rem 4.4rem;
  margin-bottom: 3.4rem;
  border-radius: 0.8rem;

  & > h3 {
    color: rgb(var(--color-primary));
    font-size: 2.4rem;
    font-weight: 700;
    margin: 3.6rem 0 1.7rem 0;
  }

  & > div:first-child {
    position: relative;
    margin: 0 auto;
    width: 16.8rem;
    height: 10.6rem;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 47.9375em) {
    & > div:first-child {
      width: 21.3rem;
      height: 12.5rem;
    }
  }
`;

export const Line = styled.div`
  height: 0.1rem;
  width: 3.55rem;
  margin: 2.3rem auto 0 auto;
  background-color: rgb(var(--color-neutral1));
`;

export const SponsorsSection = styled.section`
  position: relative;
  background-color: rgba(var(--color-primary), 0.1);
  padding: 6rem 1.8rem;
`;

export const Sponsor = styled.div`
  padding-right: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const VirtualLearningSection = styled.section`
  background-color: rgb(var(--color-secondary));
  padding: 7.1rem 4rem 8.2rem;
  gap: 7.7rem;

  @media screen and (min-width: 47.9375em) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-of-type {
      flex-basis: 40%;
    }
  }

  @media screen and (min-width: 65.625em) {
    padding: 8rem 6.4rem;
  }

  @media screen and (min-width: 75em) {
    padding: 14.1rem 15.9rem;
  }
`;
export const VirtualImageBox = styled(motion.div)`
  position: relative;
  height: 26rem;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: 47.9375em) {
    height: 41.1rem;
    margin: 0;
  }
`;
export const VirtualTextBox = styled.div`
  flex-basis: 50%;
  font-size: 3rem;
  font-weight: 600;

  p {
    margin: 3.8rem 0 2.4rem;
    line-height: 3.8rem;
  }

  span {
    color: rgb(var(--color-white));
  }

  @media screen and (min-width: 65.625em) {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
`;

export const List = styled.ul`
  padding-left: 2.4rem;
`;

export const ListItem = styled.li`
  list-style: disc;
  padding-bottom: 1.4rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;

  @media screen and (max-width: 20em) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 22.5em) {
    button {
      font-size: 1.4rem;
    }
  }

  @media screen and (min-width: 31.25em) {
    justify-content: start;
  }
`;

export const OurFeatures = styled.section`
  padding: 2.9rem 4rem 4.9rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 0.4rem;
    text-transform: initial;
  }

  h2,
  p {
    text-align: center;
  }

  p {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 31.25em) {
    p {
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 62.5em) {
    padding-top: 5.7rem;

    h2 {
      font-size: 4.8rem;
      margin-bottom: 1.1rem;
    }

    p {
      font-size: 3rem;
    }
  }

  @media screen and (min-width: 75em) {
    padding: 5.7rem 7.7rem 4.9rem 9.3rem;
  }

  @media screen and (min-width: 87.5em) {
    max-width: 140rem;
    margin: 0 auto;
  }
`;

export const Features = styled.div`
  margin-top: 3.8rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-basis: 15rem;
  margin: 0 auto 1.5rem;

  div {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
  }

  img {
    width: 100%;
    height: 100%;
  }

  p {
    margin-left: 0.4rem;
  }

  @media screen and (min-width: 31.25em) {
    flex-basis: 20rem;
  }

  @media screen and (min-width: 55em) {
    flex-basis: 23rem;
  }

  @media screen and (min-width: 62.5em) {
    flex-basis: 30rem;

    div {
      width: 2.5rem;
      height: 2.5rem;
    }

    p {
      margin-left: 1.75rem;
    }
  }

  @media screen and (min-width: 85.625em) {
    flex-basis: 35rem;
  }
`;

export const Testimonial = styled.section`
  background-color: rgba(var(--color-primary), 0.1);
  padding: 4.2rem 4.8rem;
  text-align: center;

  h2 {
    font-size: 2.88rem;
    text-transform: initial;
    margin-bottom: 1.4rem;
    font-weight: 900;
  }

  p {
    font-size: 1.92rem;
    line-height: 3rem;
    margin-bottom: 3.2rem;
  }

  @media screen and (min-width: 19.375em) {
    h2 {
      font-size: 3.6rem;
    }

    p {
      font-size: 2.4rem;
    }
  }

  @media screen and (min-width: 75em) {
    padding: 10rem 4.8rem 4.2rem 12.6rem;
  }
`;

export const Testimonials = styled.div`
  h3 {
    font-weight: 600;
  }

  & > div:nth-child(2) {
    overflow-x: auto !important;
  }

  & > div:nth-child(2)::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (min-width: 61.25em) {
    display: flex;
    gap: 6.4rem;
  }

  @media screen and (min-width: 75em) {
    gap: 12.2rem;
  }
`;

export const Excellent = styled.div`
  h3 {
    font-size: 2rem;
    margin-bottom: 0.9rem;
  }

  @media screen and (min-width: 61.25em) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const MovingTestimonials = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-top: 3.2rem;
`;

export const TestimonialBox = styled.div`
  padding: 3rem 2.4rem 1.7rem;
  font-size: 1.92rem;
  width: 25rem;

  h3 {
    margin: 1.396rem 0 1.3rem 0;
  }

  @media screen and (min-width: 18.75em) {
    padding: 3rem 3.2rem 1.7rem 3.2rem;
    width: 30rem;
  }

  @media screen and (min-width: 24.375em) {
    width: 35rem;
  }

  @media screen and (min-width: 37.5em) {
    width: 50rem;
  }
`;

export const BookDriveSection = styled.section`
  padding: 5rem 2.2rem;
  text-align: center;

  & > h2 {
    font-weight: 600;
    font-size: 3.2rem;
  }

  & > h3 {
    font-weight: 400;
    font-size: 2.4rem;
  }

  & > button {
    margin: 4rem auto 0;
  }

  @media screen and (min-width: 47.9375em) {
    padding: 5.2rem 4.5rem;

    & > h2 {
      font-size: 4rem;
    }

    & > h3 {
      font-size: 3rem;
    }
  }

  @media screen and (min-width: 61.25em) {
    padding-top: 7rem;

    & > h2 {
      font-size: 4.5rem;
    }

    & > h3 {
      font-size: 3.6rem;
      padding-bottom: 2rem;
    }

    & > button {
      margin-top: 5rem;
    }
  }

  @media screen and (min-width: 75em) {
    padding: 7rem 10rem 5.2rem;
  }
`;

export const BookDriveImagesContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6px;

  & > div {
    position: relative;
    height: 19rem;
    width: 17rem;
    display: none;

    & > img {
      object-fit: cover;
    }
  }

  & > div:nth-child(1),
  & > div:nth-child(2) {
    display: block;
  }

  @media screen and (min-width: 37.5em) {
    & > div {
      display: block;
    }
  }

  @media screen and (min-width: 61.25em) {
    margin-top: 3rem;

    & > div {
      height: 30rem;
      width: 31rem;
    }
  }

  @media screen and (min-width: 75em) {
    & > div {
      height: 35rem;
    }
  }

  @media screen and (min-width: 90em) {
    & > div {
      width: 35rem;
    }
  }
`;

export const HallOfFame = styled.section`
  & > div {
    position: relative;
    padding: 9.2rem 0 11.6rem 0;
  }

  @media screen and (min-width: 43.75em) {
    & > div {
      padding: 9.2rem 6.4rem 11.6rem;
    }
  }
`;

export const MathImage = styled(Image)`
  object-fit: cover;
  display: block;
`;

export const HallOfFameTextBox = styled.div`
  color: rgb(var(--color-white));
  text-align: center;
  background-color: rgb(var(--color-primary));
  padding-block: 6rem;

  h2 {
    font-size: 3.2rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }

  p:first-of-type {
    font-size: 2.4rem;
    margin-bottom: 0.8rem;
  }

  @media screen and (min-width: 37.5em) {
    br {
      display: none;
    }
  }

  @media screen and (min-width: 47.9375em) {
    h2 {
      font-size: 4rem;
    }

    p:first-of-type {
      font-size: 3.2rem;
    }

    p:last-child {
      font-size: 2.4rem;
    }
  }
`;

export const Reviews = styled.div`
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3.2rem;
  text-align: center;
  display: none;

  @media screen and (min-width: 47.9375em) {
    display: flex;
  }
`;

export const MobileReviews = styled.div`
  padding: 0 3.2rem;
  position: relative;
  z-index: 2;
  display: flex;
  overflow-x: auto;
  gap: 3.2rem;
  text-align: center;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (min-width: 47.9375em) {
    display: none;
  }
`;

export const CardDots = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 3.2rem;
  text-align: center;

  span {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: 1px solid rgb(var(--color-white));
    display: inline-block;
    margin-right: 0.8rem;
  }

  @media screen and (min-width: 47.9375em) {
    display: none;
  }
`;

export const Review = styled.div`
  width: 25rem;
  height: 65rem;
  border-radius: 1rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
  margin-bottom: 12.8rem;
  overflow: hidden;
  background-color: rgb(var(--color-white));
  margin: 0 auto;

  img {
    width: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 20em) {
    width: 30rem;
    height: 57rem;
  }

  @media screen and (min-width: 47.9375em) {
    flex-basis: 30rem;
  }
`;

export const ReviewCardInfo = styled.div`
  padding: 1.6rem 3.2rem 4.9rem 3.2rem;

  h3 {
    margin-bottom: 1.8rem;
    font-size: 2.3rem;
  }

  p {
    font-size: 1.8rem;
  }
`;

export const Category = styled.p`
  margin-top: 3.6rem;
  color: rgb(var(--color-primary));
  font-size: 2rem;
  font-weight: 600;
`;
