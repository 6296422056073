import { Button } from '@gate-academy/react';
import { AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useSwipeable } from 'react-swipeable';

import type { NextPageWithLayout } from './_app';

import {
  BookDriveImagesContainer,
  BookDriveSection,
  ButtonsContainer,
  CardDots,
  Category,
  Excellent,
  Feature,
  Features,
  HallOfFame,
  HallOfFameTextBox,
  HeroHeading,
  HeroHeadingContainer,
  HeroImageContainer,
  HeroImagesContainer,
  HeroSection,
  HeroSubHeading,
  Line,
  List,
  ListItem,
  MathImage,
  MobileReviews,
  MovingTestimonials,
  OurFeatures,
  OurValues,
  OurValuesBox,
  Review,
  ReviewCardInfo,
  Reviews,
  SectionHeading,
  Service,
  Services,
  ServicesSection,
  Sponsor,
  SponsorsSection,
  Testimonial,
  TestimonialBox,
  Testimonials,
  Value,
  VirtualImageBox,
  VirtualLearningSection,
  VirtualTextBox,
} from '../styles/homepage.styles';

import GeneralLayout from '../layouts/general-layout/general-layout.component';

import routes from '../lib/routes';

import { bookDriveImages } from '../data/book-drive-data';
import { cards } from '../data/cards';

import StarRating from '../components/star-rating/star-rating';

import useGetAllTestimonials from '../server-store/queries/useGetAllTestimonials';

const firstImages = ['/hero1.jpg', '/hero4.jpg'];
const secondImages = ['/hero2.jpg', '/hero5.jpg'];
const thirdImages = ['/hero3.jpg', '/hero6.jpg'];
const virtualLearningImages = ['/virtual.svg', '/virtual2.svg'];

const Homepage: NextPageWithLayout = () => {
  const router = useRouter();
  const [imageIndex, setImageIndex] = useState(0);
  const [virtualImageIndex, setVirtualImageIndex] = useState(0);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const { data } = useGetAllTestimonials();

  const testimonials = data?.data || [];

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      currentCardIndex === cards.length - 1
        ? setCurrentCardIndex(0)
        : setCurrentCardIndex((prevCard) => prevCard + 1),
    onSwipedRight: () =>
      currentCardIndex === 0
        ? setCurrentCardIndex(0)
        : setCurrentCardIndex((prevCard) => prevCard - 1),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevValue) => (prevValue >= 1 ? 0 : prevValue + 1));
      setVirtualImageIndex((prevValue) => (prevValue >= 1 ? 0 : prevValue + 1));
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, [imageIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      currentCardIndex === cards.length - 1
        ? setCurrentCardIndex(0)
        : setCurrentCardIndex((prevCard) => prevCard + 1);
    }, 8000);
    return () => {
      clearInterval(interval);
    };
  }, [currentCardIndex]);

  function changeCard() {
    currentCardIndex === cards.length - 1
      ? setCurrentCardIndex((prevIndex) => prevIndex)
      : setCurrentCardIndex((prevCard) => prevCard + 1);
  }

  return (
    <>
      {/* Hero Section */}
      <HeroSection>
        <HeroHeadingContainer>
          <HeroHeading>Let's accelerate your child's future</HeroHeading>

          <HeroSubHeading>
            The right tutor, summer program, school club and study program can
            make a difference to your child’s grade. We provide the very best so
            your child can get the highest possible grades.
          </HeroSubHeading>

          <Button onClick={() => router.push(routes.services())}>
            Get Started
          </Button>
        </HeroHeadingContainer>

        <div>
          <HeroImagesContainer>
            <AnimatePresence mode="sync">
              <HeroImageContainer
                key={imageIndex}
                data-hero1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5, ease: 'easeInOut' }}
                exit={{ opacity: 0 }}
              >
                <Image
                  src={firstImages[imageIndex]}
                  alt="intro-image"
                  fill
                  sizes="100%"
                />
              </HeroImageContainer>
            </AnimatePresence>
            <AnimatePresence mode="sync">
              <HeroImageContainer
                key={imageIndex}
                data-hero2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5, ease: 'easeInOut' }}
                exit={{ opacity: 0 }}
              >
                <Image
                  src={secondImages[imageIndex]}
                  alt="intro-image"
                  fill
                  sizes="100%"
                  priority
                />
              </HeroImageContainer>
            </AnimatePresence>
            <AnimatePresence mode="sync">
              <HeroImageContainer
                key={imageIndex}
                data-hero3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5, ease: 'easeInOut' }}
                exit={{ opacity: 0 }}
              >
                <Image
                  src={thirdImages[imageIndex]}
                  alt="intro-image"
                  fill
                  sizes="100%"
                  priority
                />
              </HeroImageContainer>
            </AnimatePresence>
          </HeroImagesContainer>
        </div>
      </HeroSection>

      {/* Our Values Section  */}
      <OurValues>
        <SectionHeading>We believe in the power of data</SectionHeading>
        <OurValuesBox>
          <Value>
            <Image src="/Frame54.png" alt="value-icon" width={60} height={60} />
            <p>
              10,000+ <br /> Trusted <br /> Professionals
            </p>
          </Value>
          <Value>
            <Image src="/Frame55.png" alt="value-icon" width={60} height={60} />
            <p>
              95% <br /> Success Rate
            </p>
          </Value>
          <Value>
            <Image src="/Frame56.png" alt="value-icon" width={60} height={60} />
            <p>
              Hybrid Model <br /> Online & Offline
            </p>
          </Value>
          <Value>
            <Image src="/Frame57.png" alt="value-icon" width={60} height={60} />
            <p>
              All <br /> Curriculum
            </p>
          </Value>
          <Value>
            <Image src="/Frame58.png" alt="value-icon" width={60} height={60} />
            <p>
              Presence In <br /> Over 10 Countries
            </p>
          </Value>
        </OurValuesBox>
      </OurValues>

      {/* Our Services Section */}
      <ServicesSection>
        <SectionHeading>Explore Our Services</SectionHeading>
        <Services>
          <Service>
            <div>
              <Image
                src="/hire-a-tutor.svg"
                alt="services-icon"
                fill
                sizes="100%"
              />
            </div>
            <Line></Line>
            <h3>Hire a Tutor</h3>
            <p>
              Our expert tutor-matching team pairs you with the most suitable
              tutor based on your specifications.
            </p>
          </Service>
          <Service>
            <div>
              <Image
                src="/assignment-help.svg"
                alt="services-icon"
                fill
                sizes="100%"
              />
            </div>
            <Line></Line>
            <h3>Assignment Help</h3>
            <p>
              Get step-by-step workings with explanations to difficult questions
              on all of your homework and assignments in any topic and subject.
            </p>
          </Service>
          <Service>
            <div>
              <Image
                src="/test-and-exam.svg"
                alt="services-icon"
                fill
                sizes="100%"
              />
            </div>
            <Line></Line>
            <h3>Test & Exam Prep</h3>
            <p>
              We specialize in preparing students for both local and
              international exams. 97.3% of our students pass all exams in one
              sitting with high flying grades.
            </p>
          </Service>
          <Service>
            <div>
              <Image
                src="/summer-coaching.svg"
                alt="services-icon"
                fill
                sizes="100%"
              />
            </div>
            <Line></Line>
            <h3>Summer-Coaching</h3>
            <p>
              Taking a summer vacation? Do not fret! We provide summer coaching
              classes to students. This can be physical or virtual.
            </p>
          </Service>
          <Service>
            <div>
              <Image
                src="/E-library.svg"
                alt="services-icon"
                fill
                sizes="100%"
              />
            </div>
            <Line></Line>
            <h3>E-library</h3>
            <p>
              Access to over 50,000 study questions and past exams, as well as
              thousands of topics in various subjects.
            </p>
          </Service>
          <Service>
            <div>
              <Image
                src="/writing-services.svg"
                alt="services-icon"
                fill
                sizes="100%"
              />
            </div>
            <Line></Line>
            <h3>Writing services</h3>
            <p>
              We offer in-depth professional and plagiarism free writing
              services to custom essays, dissertations, pitch decks and writing
              projects.
            </p>
          </Service>
        </Services>
        <Button onClick={() => router.push(routes.services())}>
          Get Started
        </Button>
      </ServicesSection>

      {/* Our Sponsors Section  */}
      <SponsorsSection>
        <Marquee gradient={false} speed={60} autoFill={true}>
          <Sponsor>
            <Image
              src="/image19.svg"
              alt="sponsor-img"
              width={75}
              height={75}
            />
          </Sponsor>
          <Sponsor>
            <Image
              src="/image20.svg"
              alt="sponsor-img"
              width={112}
              height={37}
            />
          </Sponsor>
          <Sponsor>
            <Image
              src="/image21.svg"
              alt="sponsor-img"
              width={108}
              height={60}
            />
          </Sponsor>
          <Sponsor>
            <Image
              src="/image22.svg"
              alt="sponsor-img"
              width={87}
              height={28}
            />
          </Sponsor>
          <Sponsor>
            <Image
              src="/image23.svg"
              alt="sponsor-img"
              width={87}
              height={75}
            />
          </Sponsor>
          <Sponsor>
            <Image
              src="/image24.svg"
              alt="sponsor-img"
              width={105}
              height={108}
            />
          </Sponsor>
          <Sponsor>
            <Image
              src="/image25.svg"
              alt="sponsor-img"
              width={101}
              height={37}
            />
          </Sponsor>
          <Sponsor>
            <Image
              src="/image26.svg"
              alt="sponsor-img"
              width={168}
              height={22}
            />
          </Sponsor>
        </Marquee>
      </SponsorsSection>

      {/* Virtual Learning Section  */}
      <VirtualLearningSection>
        <AnimatePresence mode="wait">
          <VirtualImageBox
            key={virtualImageIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            exit={{ opacity: 0 }}
          >
            <Image
              src={virtualLearningImages[virtualImageIndex]}
              alt="virtual-image"
              fill
              sizes="100%"
              priority
            />
          </VirtualImageBox>
        </AnimatePresence>
        <VirtualTextBox>
          <p>
            Have classes <span>anywhere </span> in the world with our online
            learning suite
          </p>
          <List>
            <ListItem>Whiteboard function</ListItem>
            <ListItem>Screen sharing</ListItem>
            <ListItem>Recording function</ListItem>
          </List>
          <ButtonsContainer>
            <Button blackBg onClick={() => router.push(routes.hireTutor())}>
              Hire a tutor
            </Button>
            <Button transparentBg>
              <Link href="https://legacy.gateacademy.com.ng/become-a-tutor/">
                Become a tutor
              </Link>
            </Button>
          </ButtonsContainer>
        </VirtualTextBox>
      </VirtualLearningSection>

      {/* Our Features Section  */}
      <OurFeatures>
        <SectionHeading>Tutoring Just Got Easier</SectionHeading>
        <p>Top GATE Academy Features;</p>
        <Features>
          <Feature>
            <div>
              <Image src="/checkbox.svg" alt="checkbox" fill sizes="100%" />
            </div>
            <p>Flexible booking</p>
          </Feature>
          <Feature>
            <div>
              <Image src="/checkbox.svg" alt="checkbox" fill sizes="100%" />
            </div>
            <p>Easy payment</p>
          </Feature>
          <Feature>
            <div>
              <Image src="/checkbox.svg" alt="checkbox" fill sizes="100%" />
            </div>
            <p>Progress tracking</p>
          </Feature>
          <Feature>
            <div>
              <Image src="/checkbox.svg" alt="checkbox" fill sizes="100%" />
            </div>
            <p>Tutor replacement</p>
          </Feature>
          <Feature>
            <div>
              <Image src="/checkbox.svg" alt="checkbox" fill sizes="100%" />
            </div>
            <p>Checks & balances</p>
          </Feature>
          <Feature>
            <div>
              <Image src="/checkbox.svg" alt="checkbox" fill sizes="100%" />
            </div>
            <p>Continuous support</p>
          </Feature>
        </Features>
      </OurFeatures>

      {/* Testimonials Section  */}
      <Testimonial>
        <SectionHeading>
          Recommended by parents, students and teachers
        </SectionHeading>
        <p>Thousands of parents and students already rely on GATE Academy</p>
        <Testimonials>
          <Excellent>
            <h3>Excellent</h3>
            <StarRating rating={5} />
          </Excellent>
          <Marquee speed={20} gradient={false} pauseOnHover={true}>
            <MovingTestimonials>
              {testimonials.map((review) => {
                return (
                  <TestimonialBox key={review.id}>
                    <StarRating rating={5} />
                    <h3>{review.name}</h3>
                    <p>{review.message}</p>
                  </TestimonialBox>
                );
              })}
            </MovingTestimonials>
          </Marquee>
        </Testimonials>
      </Testimonial>

      {/* Book Drive Section  */}
      <BookDriveSection>
        <h2>The Book Drive Initiative.</h2>
        <h3>Join our book drive</h3>
        <p>Empowering students with limited access to educational materials</p>
        <BookDriveImagesContainer>
          {bookDriveImages.map((bookDriveImage, index) => (
            <div key={index}>
              <Image
                src={bookDriveImage}
                fill
                sizes="100%"
                alt="hero-image"
                placeholder="blur"
              />
            </div>
          ))}
        </BookDriveImagesContainer>
        <Button>
          <Link href={routes.bookDrive()}>GET INVOLVED</Link>
        </Button>
      </BookDriveSection>

      {/* Hall of Fame Section  */}
      <HallOfFame>
        <HallOfFameTextBox>
          <h2>
            GATE Academy <br /> Math Challenge
          </h2>
          <p>Hall of Fame</p>
          <p>Our Top 4!!!</p>
        </HallOfFameTextBox>
        <div>
          <MathImage src="/math.svg" alt="math-challenge-image" fill />
          <MobileReviews {...handlers}>
            <Review>
              {cards
                .slice(currentCardIndex, currentCardIndex + 1)
                .map((card) => (
                  <div key={currentCardIndex}>
                    <Image
                      src={card.src}
                      alt="review-image"
                      width={300}
                      height={184}
                    />
                    <ReviewCardInfo>
                      <h3>{card.name}</h3>
                      <p>{card.comment}</p>
                      <Category>{card.category}</Category>
                    </ReviewCardInfo>
                  </div>
                ))}
            </Review>
          </MobileReviews>
          <Reviews>
            {cards.map((card, index) => (
              <Review key={index}>
                <Image
                  src={card.src}
                  alt="review-image"
                  width={300}
                  height={184}
                />
                <ReviewCardInfo>
                  <h3>{card.name}</h3>
                  <p>{card.comment}</p>
                  <Category>{card.category}</Category>
                </ReviewCardInfo>
              </Review>
            ))}
          </Reviews>
          <CardDots>
            {cards.map((card, index) => (
              <span
                key={index}
                style={{
                  backgroundColor:
                    currentCardIndex === index ? 'white' : 'transparent',
                }}
                onClick={changeCard}
              ></span>
            ))}
          </CardDots>
        </div>
      </HallOfFame>
    </>
  );
};

Homepage.getLayout = function (page: ReactElement) {
  return (
    <GeneralLayout pageTitle="A hub of affordable and efficient private tutors">
      {page}
    </GeneralLayout>
  );
};

export default Homepage;
