import Image from 'next/image';
import { StarBox, Star } from './star-rating.styles';

export default function StarRating({ rating }: { rating: number }) {
  const star = Math.floor(rating);
  const stars = [];

  for (let i = 0; i < star; i++) {
    stars.push(
      <Star key={i}>
        <Image src="/star.svg" width={25} height={25} alt="star" />
      </Star>
    );
  }
  return <StarBox>{stars}</StarBox>;
}
