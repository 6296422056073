import styled from 'styled-components';
export const StarBox = styled.div`
  display: flex;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.2rem;
    height: 3rem;
    width: 3rem;
    background-color: rgb(var(--color-primary));
  }

  img {
    margin-right: 0.2rem;
  }
`;

export const Star = styled.div`
  background-color: rgb(var(--color-primary));
`;
